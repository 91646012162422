import React, { useState, useEffect } from 'react';
import { Box, Button, Text } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components';
import atomize from '@quarkly/atomize';
import Cookies from 'js-cookie';
import { motion } from 'framer-motion';
const MotionBox = motion(Box);
const overrides = {
	PopupBox: {
		kind: 'Box',
		props: {
			position: 'fixed',
			border: '1px solid #ccc',
			display: 'none',
			zIndex: '1000',
			"background": "--color-brandLight",
			"padding": "30px 20px 30px 20px",
			"border-radius": "16px",
			"box-shadow": "--xl",
			"opacity": "0.95",
			"top": "20vh",
			"right": "5px"
		}
	},
	PopupButton: {
		kind: 'Button',
		props: {
			"background": "--color-brandCta",
			"box-shadow": "--m"
		}
	},
	CloseButton: {
		kind: 'Button',
		props: {
			position: 'absolute',
			top: '-1px',
			right: '-1px',
			padding: "0.5em 1em 0.5em 1em",
			border: 'none',
			cursor: 'pointer',
			"border-radius": "0 16px  0 16px",
			"background": "--color-brandPrimary",
			"font": "normal 400 1em/1.5 --fontFamily-googleLexend",
			"color": "--brandLight"
		}
	},
	"ModalBody": {
		"props": {
			"font": "--lead"
		}
	}
};

const PromoPopup = ({
	cta,
	buttonLabel,
	targetUrl,
	timing,
	...props
}) => {
	const [showPopup, setShowPopup] = useState(false);
	const {
		override,
		rest
	} = useOverrides(props, overrides);
	useEffect(() => {
		const popupClosed = Cookies.get('popupClosed');

		if (popupClosed) {
			return; // Don't show popup if the cookie exists
		}

		const handleExitIntent = e => {
			if (timing === 'exit' || timing === 'both') {
				if (e.clientY < 0) setShowPopup(true);
			}
		};

		if (timing === '10s' || timing === 'both') {
			const timer = setTimeout(() => {
				setShowPopup(true);
			}, 10000);
			return () => clearTimeout(timer);
		}

		document.addEventListener('mouseout', handleExitIntent);
		return () => {
			document.removeEventListener('mouseout', handleExitIntent);
		};
	}, [timing]);

	const handleClose = () => {
		setShowPopup(false); // Set a cookie that expires in 3 days

		Cookies.set('popupClosed', 'true', {
			expires: 3
		});
	};

	return <Box {...rest}>
		      
		<MotionBox {...override('PopupBox')} style={{
			display: showPopup ? 'block' : 'none'
		}} drag dragConstraints={{
			top: 0,
			left: 0,
			right: 0,
			bottom: 0
		}}>
			        
			<Button {...override('CloseButton')} onClick={handleClose}>
				          {"X"}        
			</Button>
			        
			<Text {...override('ModalBody')}>
				{cta}
			</Text>
			        
			<Button {...override('PopupButton')} onClick={() => window.open(targetUrl, '_blank')}>
				          
				{buttonLabel}
				        
			</Button>
			      
		</MotionBox>
		    
	</Box>;
};

export default atomize(PromoPopup)({
	name: 'PopupModal',
	description: {
		en: 'Sticky popup modal with CTA button that appears based on timing or exit intent, and respects user preference for 3 days.'
	},
	propInfo: {
		cta: {
			title: 'Call to Action',
			description: 'Content of the modal',
			control: 'input',
			type: 'string',
			category: 'Main'
		},
		buttonLabel: {
			title: 'Button Label',
			description: 'Label of the button',
			control: 'input',
			type: 'string',
			category: 'Main'
		},
		targetUrl: {
			title: 'Target URL',
			description: 'URL to open when button is clicked',
			control: 'input',
			type: 'string',
			category: 'Main'
		},
		timing: {
			title: 'Popup Timing',
			description: 'When to show the popup (after 10s, on exit, or both)',
			control: 'select',
			variants: ['10s', 'exit', 'both'],
			category: 'Main'
		}
	},
	defaultProps: {
		cta: 'Sign up for our newsletter!',
		buttonLabel: 'Join now',
		targetUrl: 'https://example.com',
		timing: 'both'
	}
});