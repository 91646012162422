import React, { useState, useEffect } from "react";
import { useOverrides } from "@quarkly/components";
import { Box } from "@quarkly/widgets";
import { motion } from "framer-motion";
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px"
};
const overrides = {};
const MotionBox = motion(Box);

const TeamWrapper = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [selectedId, setSelectedId] = useState(null);

	const handleSelect = (id, event) => {
		event.stopPropagation();

		if (selectedId === id) {
			handleDeselect();
		} else {
			setSelectedId(id);
		} //console.log(id);

	};

	const handleDeselect = () => setSelectedId(null);

	useEffect(() => {
		const clickListener = e => {
			handleDeselect();
		}; // Attach click event listener to the whole window


		window.addEventListener('click', clickListener); // Cleanup the event listener on component unmount

		return () => {
			window.removeEventListener('click', clickListener);
		};
	}, []);
	return <MotionBox grid {...rest}>
		{React.Children.map(children, (child, index) => React.cloneElement(child, {
			as: MotionBox,
			layout: true,
			onClick: e => handleSelect(index, e),

			/*variants: variants,
   initial: 'default',
   animate: selectedId === index ? 'expanded' : 'default',*/
			isSelected: selectedId === index,
			index: index,
			key: index
		}))}
	</MotionBox>;
};

Object.assign(TeamWrapper, { ...Box,
	defaultProps,
	overrides
});
export default TeamWrapper;