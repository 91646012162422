import React, { useEffect, useState, useRef } from "react";
import { useOverrides } from "@quarkly/components";
import atomize from "@quarkly/atomize";
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation, useTransform, useScroll } from "framer-motion";
import { Image, Link, Box, Section } from "@quarkly/widgets";
const AnimatedBox = motion(Box);
const animations = {
	bounceIn: {
		visible: {
			opacity: 1,
			scale: 1,
			x: 0,
			transition: {
				duration: 0.6,
				ease: [0, 0.5, 0.2, 1.01],
				scale: {
					type: "spring",
					damping: 11,
					stiffness: 70,
					restDelta: 0.001
				}
			}
		},
		hidden: {
			opacity: 0,
			scale: 0,
			x: 100,
			transition: {
				duration: 1,
				ease: [0, 0.5, 0.2, 1.01]
			}
		}
	},
	rotate: {
		visible: {
			opacity: 1,
			rotate: 720,
			x: 0,
			transition: {
				duration: 5,
				ease: 'circInOut',
				type: 'spring',
				stiffness: 6,
				damping: 2
			}
		},
		hidden: {
			opacity: 1,
			rotate: 0,
			x: 0,
			transition: {
				duration: 1,
				ease: [0, 0.5, 0.2, 1.01]
			}
		}
	},
	rotateInfinity: {
		visible: {
			rotate: 360,
			transition: {
				duration: 5,
				repeat: Infinity,
				ease: 'linear'
			}
		},
		hidden: {}
	}
};

const Animate = ({
	isVisible,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, {}, {});
	const scrollRef = useRef(null);
	const control = useAnimation();
	const {
		ref,
		inView
	} = useInView({
		triggerOnce: false,
		threshold: 0.5 //rootMargin: `1000px`,

	});
	useEffect(() => {
		if (inView) {
			control.start("visible");
		} else {
			control.start("hidden");
		} //console.log("inView =", inView);

	}, [control, inView]);
	return <Box ref={scrollRef} {...rest}>
		      
		<AnimatedBox
			className="Box"
			variants={animations[props.animationType]}
			initial="hidden"
			whileInView="visible"
			viewport={{
				margin: "-20%"
			}} //animate={control}

		>
			        
			{children}
			      
		</AnimatedBox>
		    
	</Box>;
};

export default atomize(Animate)({
	name: "AniMate component",
	description: "A component that applies animations as it enters the viewport.",
	propInfo: {
		overrides: {
			type: "object",
			description: "Customize parts of the accordion component."
		},
		animationType: {
			type: "select",
			variants: ['bounceIn', 'rotate', 'rotateInfinity'],
			category: "Main"
		}
	}
}, {
	animationType: 'bounceIn'
});