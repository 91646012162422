import React, { useEffect, useState, useRef } from "react";
import { useOverrides } from "@quarkly/components";
import atomize from "@quarkly/atomize";
import { motion, useScroll, useSpring } from "framer-motion";
import { Box } from "@quarkly/widgets";
const AnimatedBox = motion(Box);
const customScrollbarStyles = `
  /* Customizes the whole scrollbar */
  .scroll-content::-webkit-scrollbar {
    width: 25px; /* width of the entire scrollbar */
  }

  /* Customizes the scrollbar track */
  .scroll-content::-webkit-scrollbar-track {
    background: #f0f0f0; /* color of the tracking area */
  }

  /* Customizes the scrollbar thumb */
  .scroll-content::-webkit-scrollbar-thumb {
    background-color: #e6294f; /* color of the thumb */
    border-radius: 15px; /* roundness of the corners */
    border: 3px solid transparent; /* creates padding around the thumb */
    
  }

  /* Changes the scrollbar thumb color on hover */
  .scroll-content::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the thumb when hovered */
  }

  /* For Firefox */
  html {
    .scroll-content scrollbar-width: thin; /* or "auto" or "thin" */
    .scroll-content scrollbar-color: #e6294f #f0f0f0; /* thumb and track color */
  }
`;

const ScrollProgressBar = ({
	isVisible,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, {}, {});
	const ref = useRef(null);
	const {
		scrollYProgress
	} = useScroll({
		container: ref
	});
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 80,
		damping: 10,
		restDelta: 0.001
	});
	return <Box position="relative" {...rest}>
		      
		<style>
			{customScrollbarStyles}
		</style>
		      
		<AnimatedBox
			className="progress-bar"
			position="relative"
			top="0"
			left="0"
			right="0"
			height="10px"
			background="--color-brandCta"
			transform-origin="0%"
			style={{
				scaleX
			}}
			{...override('Bar')}
		/>
		      
		<Box ref={ref} className="scroll-content" {...override('Content')}>
			        
			{children}
			      
		</Box>
		    
	</Box>;
};

export default atomize(ScrollProgressBar)({
	name: "AniMate component",
	description: "A component that applies animations as it enters the viewport.",
	propInfo: {
		overrides: {
			type: "object",
			description: "Customize parts of the accordion component."
		}
	}
});