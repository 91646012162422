import React, { useState, useEffect, useMemo } from 'react';
import atomize from '@quarkly/atomize';
import { Box, Icon } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components'; // Function to shuffle an array (Fisher-Yates shuffle)

import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function shuffleArray(array) {
	let currentIndex = array.length;
	let randomIndex = 1; // While there remain elements to shuffle...

	while (currentIndex !== 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--; // And swap it with the current element.

		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
}

const Carousel = ({
	slideInterval,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, {}, {});
	const [processedChildren, setProcessedChildren] = useState([]);
	const [totalSlides, setTotalSlides] = useState(0);
	useEffect(() => {
		setProcessedChildren(shuffleArray(children));
		setTotalSlides(children.length - 3); // 3 child will be excluded at rendering!
	}, []);
	const [currentIndex, setCurrentIndex] = useState(0); //const totalSlides = processedChildren.length;
	// Set up the automatic slide movement

	useEffect(() => {
		const interval = setInterval(() => {
			goToNext(); // Move to the next slide at each interval
		}, slideInterval); // Clear the interval when the component unmounts

		return () => clearInterval(interval);
	}, [currentIndex, slideInterval]);

	const goToPrevious = () => {
		setCurrentIndex(currentIndex === 0 ? totalSlides - 1 : currentIndex - 1);
	};

	const goToNext = () => {
		setCurrentIndex(currentIndex === totalSlides - 1 ? 0 : currentIndex + 1);
	};

	return <Box {...rest} display="flex" flex-direction="row">
		      
		<Box
			width="10%"
			display="flex"
			align-items="center"
			justify-content="center"
			border-radius="15px"
			onClick={goToPrevious}
		>
			        
			<Icon
				{...override("PrevIcon")}
				border-radius="15px"
				category="md"
				icon={MdChevronLeft}
				size="64px"
				transform="scaleY(2)"
				background="--brandLight"
				opacity="0.8"
				color="--color-brandSecondary"
			/>
			      
		</Box>
		      
		<Box width="80%" overflow="hidden">
			        
			<Box {...override('Slides')} display="flex" transition="transform 0.5s ease" transform={`translateX(-${currentIndex * 100}%)`}>
				          
				{React.Children.map(processedChildren, (child, index) => {
					if (child.props.slot && (/Slides/.test(child.props.slot) || /Icon/.test(child.props.slot))) {
						return null; // Skip rendering this child
					}

					return <Box {...override("Slide")} key={`slide-${index}`} width="100%" flex="0 0 auto">
						                
						{child}
						              
					</Box>;
				})}
				        
			</Box>
			      
		</Box>
		      
		<Box
			width="10%"
			display="flex"
			align-items="center"
			justify-content="center"
			onClick={goToNext}
		>
			        
			<Icon
				{...override("NextIcon")}
				border-radius="15px"
				category="md"
				icon={MdChevronRight}
				size="64px"
				transform="scaleY(2)"
				background="--brandLight"
				opacity="0.8"
				color="--color-brandSecondary"
			/>
			      
		</Box>
		    
	</Box>;
};

export default atomize(Carousel)({
	name: "Carousel",
	description: "A simple carousel component that accepts children as slides, with randomized slide order.",
	propInfo: {
		// Here, you could document the overridable parts
		overrides: {
			type: "object",
			description: "Customize parts of the carousel component."
		},
		slideInterval: {
			title: "Slide wait time (ms)",
			control: "input",
			category: "Main"
		}
	}
}, {
	slideInterval: "3000"
});