import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import atomize from '@quarkly/atomize';
import { useOverrides } from '@quarkly/components';
const animations = {
	fadeIn: {
		from: {
			opacity: 0,
			transition: 'opacity 0.6s ease-in-out'
		},
		to: {
			opacity: 1,
			transition: 'opacity 0.6s ease-in-out'
		}
	},
	slideUp: {
		from: {
			transform: 'translateY(-160px)',
			opacity: 0,
			transition: 'transform 0.6s ease-in-out, opacity 0.6s ease-in-out'
		},
		to: {
			transform: 'translateY(0)',
			opacity: 1,
			transition: 'transform 0.6s ease-in-out, opacity 0.6s ease-in-out'
		}
	},
	slideRight: {
		from: {
			transform: 'translateX(200px)',
			opacity: 0,
			transition: 'transform 0.6s ease-in-out, opacity 0.6s ease-in-out'
		},
		to: {
			transform: 'translateX(0px)',
			opacity: 1,
			transition: 'transform 0.6s ease-in-out, opacity 0.6s ease-in-out'
		}
	}
};
const AnimatedOnScroll = atomize(props => {
	const {
		children,
		rest
	} = useOverrides(props, {}, {});
	const [animationStyle, setAnimationStyle] = useState({});
	const {
		ref,
		inView
	} = useInView({
		triggerOnce: props.triggerOnce === 'Yes',
		threshold: props.threshold
	});
	useEffect(() => {
		const applyAnimation = inView ? animations[props.animationType].to : animations[props.animationType].from;
		setAnimationStyle(applyAnimation); // Log to see the animation direction based on inView state

		console.log("Animating...", inView ? "forward" : "reverse");
	}, [inView, props.animationType]);
	return <div ref={ref} {...rest} style={{ ...animationStyle
	}}>
		    
		{children}
		  
	</div>;
})({
	name: "Animated On Scroll",
	overrides: {}
});
export default atomize(AnimatedOnScroll)({
	description: {
		en: "A component that applies animations to its children based on viewport visibility."
	},
	propInfo: {
		animationType: {
			control: "select",
			variants: ["fadeIn", "slideUp", "slideRight"],
			category: "Main",
			weight: 1
		},
		triggerOnce: {
			control: "select",
			variants: ['Yes', 'No'],
			category: "Main"
		},
		threshold: {
			control: "number",
			category: "Main"
		}
	}
}, {
	animationType: 'fadeIn',
	triggerOnce: 'No',
	threshold: 0.5
});