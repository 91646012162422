import React from "react";
import { useOverrides } from "@quarkly/components";
import QuarklycommunityKitPopup from "./QuarklycommunityKitPopup";
const defaultProps = {
	"text-align": "center",
	"margin": "0px auto 0px auto"
};
const overrides = {
	"Button Open": {
		"props": {
			"color": "--brandLight",
			"background": "--color-brandCta",
			"text-decoration-line": "initial",
			"font": "--lead",
			"border-radius": "8px",
			"transition": "background-color 0.2s ease-in-out 0s",
			"hover-background": "--color-orange",
			"sm-margin": "15px 0px 15px 0px",
			"sm-text-align": "center",
			"sm-width": "60%",
			"hover-transition": "background-color 0.2s ease-in-out 0s",
			"target": "_blank",
			"sm-align-self": "center",
			"align-self": "center",
			"padding": "12px 24px 12px 24px",
			"children": "Letöltöm"
		}
	},
	"Popup :closed": {
		"props": {}
	},
	"Content": {
		"props": {
			"margin": "15px 25px 15px 25px"
		}
	},
	"Wrapper": {
		"props": {
			"border-radius": "15px",
			"opacity": "0.95"
		}
	}
};

const CtaPopUp = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitPopup {...rest}>
		{children}
	</QuarklycommunityKitPopup>;
};

Object.assign(CtaPopUp, { ...QuarklycommunityKitPopup,
	defaultProps,
	overrides
});
export default CtaPopUp;