import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, Text, Span, Strong, Box, Link } from "@quarkly/widgets";
import { FaRegNewspaper } from "react-icons/fa";
const defaultProps = {
	"padding": "45px 45px",
	"lg-padding": "45px 30px",
	"md-padding": "45px 45px",
	"background": "#FFFFFF",
	"border-radius": "24px",
	"justify-content": "flex-start",
	"flex-direction": "column",
	"display": "flex",
	"position": "relative"
};
const overrides = {
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaRegNewspaper,
			"margin": "0px 0px 30px 0px",
			"color": "--dark",
			"size": "48px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 2px 0px",
			"color": "--darkL2",
			"font": "--cardH3",
			"lg-text-align": "left",
			"children": <>
				Blöffölt Putyin az atom bevetésével?
				<br />
				A testbeszéde buktatta le..
			</>,
			"height": "96px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "--base",
			"lg-text-align": "left",
			"flex": "1 0 auto",
			"text-align": "center",
			"position": "absolute",
			"top": "15px",
			"right": "15px",
			"text-shadow": "0 0 1px --color-brandPrimary",
			"color": "--brandPrimary",
			"opacity": "0.6",
			"children": <Span
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
				text-align="right"
				right={0}
				position="static"
			>
				2022. szeptember 27.
			</Span>
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"color": "--greyD3",
			"font": "--base",
			"lg-text-align": "left",
			"flex": "1 0 auto",
			"text-align": "left",
			"margin": "15px 0px 0px 0px",
			"children": <>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					TV műsor:
				</Strong>
				{" "}Hírtévé, Index.hu
			</>
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"position": "relative",
			"display": "flex",
			"quarkly-title": "LogoWrapper",
			"text-align": "center",
			"justify-content": "center"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"max-width": "100px",
			"max-height": "100px",
			"background": "rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/hirtv.png?v=2023-12-01T19:01:37.253Z) 0% 0% /contain no-repeat scroll padding-box",
			"display": "inline",
			"min-width": "100px",
			"min-height": "43px",
			"align-self": "center",
			"align-content": "center",
			"position": "relative",
			"order": "0"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"max-width": "100px",
			"max-height": "100px",
			"background": "rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/indexhu.svg?v=2023-11-23T15:29:09.172Z) 0% 0% /contain no-repeat scroll padding-box",
			"display": "inline",
			"min-width": "143px",
			"min-height": "43px",
			"align-content": "center",
			"position": "relative",
			"align-self": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "--brandSecondary",
			"padding": "15px 25px 15px 25px",
			"text-decoration": "none",
			"text-align": "center",
			"background": "--color-brandLight",
			"border-radius": "15px",
			"font": "--base",
			"letter-spacing": "1.2px",
			"width": "fit-content",
			"align-self": "center",
			"margin": "10px 0px 0px 0px",
			"hover-background": "--color-brandCta",
			"hover-border-color": "--color-brandSecondary",
			"hover-color": "--brandLight",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Megnézem >>
			</Strong>,
			"href": "#",
			"target": "_blank",
			"rel": "nofollow"
		}
	}
};

const CardItem = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		<Icon {...override("icon")} />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Text {...override("text2")} />
		<Box {...override("box")}>
			<Box {...override("box1")} />
			<Box {...override("box2")} />
		</Box>
		<Link {...override("link")} />
		{children}
	</Box>;
};

Object.assign(CardItem, { ...Box,
	defaultProps,
	overrides
});
export default CardItem;