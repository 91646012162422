import React from 'react';
import atomize from "@quarkly/atomize";
import { useOverrides } from "@quarkly/components";
import { Box } from "@quarkly/widgets";
import { InlineWidget } from "react-calendly";
const widgetStyles = {
	height: '1300px' // Adjust this value based on the actual content height you need

};

const CalendlyBook = ({
	url,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, {}, {});
	return <Box {...rest}>
		      
		<InlineWidget url={url + "?hide_gdpr_banner=1"} styles={widgetStyles} />
		    
	</Box>;
};

export default atomize(CalendlyBook)({
	name: "CalendlyBook",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "CalendlyBook — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		overrides: {
			type: "object",
			description: "Customize parts of the accordion component."
		},
		url: {
			title: "Your calendly sheduling page",
			type: "input",
			category: "Main"
		}
	}
}, {
	url: "https://calendly.com/your_scheduling_page"
});