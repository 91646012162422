import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    },
    "index": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend",
        "width": "100v"
    },
    "szolgaltatasok": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    },
    "about": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    },
    "blog": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    },
    "sikeres-munkahelyi-kommunikacio": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    },
    "6mx-profilozas": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    },
    "terms": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    },
    "privacy": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    },
    "5m": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    },
    "cookies": {
        "letter-spacing": "",
        "font": "16px --fontFamily-googleLexend"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
