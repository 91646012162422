import React, { useState, useEffect } from 'react';
import atomize from '@quarkly/atomize';
import { Box, Icon } from '@quarkly/widgets';
import { Transition } from "react-transition-group";
import { useOverrides } from '@quarkly/components';
import { FaChevronUp } from "react-icons/fa";

const Accordion = ({
	closedHeight,
	initOpen,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, {}, {});
	const [activeIndex, setActiveIndex] = useState(2);

	const handleClick = index => {
		setActiveIndex(index === activeIndex ? null : index);
	};

	return <Box {...rest}>
		      
		{React.Children.map(children, (child, index) => {
			// Check if the current child has the key
			if (child.props.slot && (/Arrow/.test(child.props.slot) || /Accordion/.test(child.props.slot))) {
				return null; // Skip rendering this child
			}

			return <Transition in={activeIndex === index} timeout={300} onEnter={() => {}} onExit={() => {}}>
				            
				{state => {
					const contentVisible = state === "entered" || state === "entering";
					const maxHeight = contentVisible ? "1000px" : closedHeight;
					return <Box display="flex" onClick={() => handleClick(index)}>
						                  
						<Box style={{
							maxHeight,
							transition: "max-height 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s",
							overflow: "hidden",
							willChange: "max-height",
							width: "100%"
						}}>
							                    
							{child}
							                  
						</Box>
						                  
						<Box display="flex" position="relative" align-items="start" justify-content="left">
							                    
							<Icon
								category="fa"
								transform={contentVisible ? "rotateZ(-180deg)" : "none"}
								transition="--transformInOut"
								icon={FaChevronUp}
								size="26px"
								position="absolute"
								right="50px"
								sm-right="20px"
								top="15px"
								zIndex="10"
							/>
							                  
						</Box>
						                
					</Box>;
				}}
				    
			</Transition>;
		})}
		    
	</Box>;
};

export default atomize(Accordion)({
	name: "Accordion",
	description: "A simple accordion component that accepts children as items. Each item is an accordion item that can be expanded or collapsed.",
	propInfo: {
		overrides: {
			type: "object",
			description: "Customize parts of the accordion component."
		},
		closedHeight: {
			title: "Accordion item's closed height",
			control: "input",
			category: "Main"
		},
		initOpen: {
			title: "Initial open accordion item. (null if none)",
			control: "input",
			category: "Main"
		}
	}
}, {
	closedHeight: "135px",
	initOpen: 1
});