import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Input, Box, Button, Link } from "@quarkly/widgets";
import QuarklycommunityKitMailChimp from "./QuarklycommunityKitMailChimp"; // Check values from https://us1.admin.mailchimp.com/audience/tags/lazy/

const propInfo = {
	baseUrl: {
		title: "Embed Form's URL",
		description: "The URL for the MC subscription form https://.../post?",
		control: "input",
		type: "text",
		category: "Main",
		weight: 1
	},
	userId: {
		title: "MC User ID",
		description: "The value for the MC user ID. u=...",
		control: "input",
		type: "text",
		category: "Main",
		weight: 1
	},
	formId: {
		title: "MC Form ID",
		description: "The value for the Mailchimp form. f_id=...",
		control: "input",
		type: "text",
		category: "Main",
		weight: 1
	},
	listId: {
		title: "MC Audience List Unique ID",
		description: "The value for the Mailchimp list",
		control: "input",
		type: "text",
		category: "Main",
		weight: 1
	},
	tagId: {
		title: "MC Tag ID",
		description: "The value for the Mailchimp tag",
		control: "input",
		type: "text",
		category: "Main",
		weight: 1
	}
};
const defaultProps = {
	baseUrl: "https://sziklaybence.us1.list-manage.com/subscribe/post?",
	userId: "824e28e9300f8a1305ad8f798",
	formId: "009d07e2f0",
	"tagValue": "6322663",
	"audienceListId": "869d1a2b1",
	"margin": "0px auto 0px auto",
	"listId": "required",
	"tagId": "no tag"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"gap": "16px",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"display": "inline",
			"width": "45%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0 0 4px 0",
			"font": "--base",
			"children": "Vezetéknév",
			"display": "none"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"max-width": "400px",
			"width": "100%",
			"type": "text",
			"name": "LNAME",
			"background": "--color-brandLight",
			"placeholder": "Vezetéknév"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"display": "inline",
			"width": "45%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0 0 4px 0",
			"font": "--base",
			"display": "none",
			"children": "Keresztnév"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"max-width": "400px",
			"width": "100%",
			"type": "text",
			"name": "FNAME",
			"background": "--color-brandLight",
			"required": true,
			"placeholder": "Keresztnév"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"padding": "8px 8px 8px 8px"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"margin": "0 0 4px 0",
			"children": "E-mail cím",
			"display": "none"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"max-width": "400px",
			"width": "100%",
			"type": "email",
			"name": "EMAIL",
			"background": "--color-brandLight",
			"required": true,
			"placeholder": "Email cím"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"display": "inline-block",
			"width": "85%",
			"font": "--base",
			"margin": "0 0 4px 0",
			"color": "--brandPrimary",
			"opacity": "0.8",
			"text-align": "left",
			"children": <>
				Az{" "}
				<Link
					href="../terms"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Adatkezelési tájékoztatót
				</Link>
				{" "}megismertem és elfogadom, hozzájárulok, hogy hírlevelet küldjetek nekem.
			</>
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"input3": {
		"kind": "Input",
		"props": {
			"display": "inline-block",
			"width": "10%",
			"placeholder-color": "LightGray",
			"background": "white",
			"name": "Checked",
			"type": "checkbox",
			"quarkly-title": "Checkbox",
			"required": true,
			"min-width": "25px",
			"min-height": "25px",
			"color": "--brandSecondary",
			"border-color": "--color-brandSecondary"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"quarkly-title": "AudienceTag",
			"display": "none"
		}
	},
	"input4": {
		"kind": "Input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white",
			"name": "tags",
			"value": "6322663",
			"type": "hidden",
			"quarkly-title": "AudienceTag"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"padding": "8px 8px 8px 8px"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"hover-background": "--color-orange",
			"font": "--lead",
			"background": "--color-brandCta",
			"opacity": "1",
			"margin": "0px auto 0px auto"
		}
	},
	"Form": {
		"props": {
			"margin": "15px 15px 15px 15px"
		}
	}
};

const MailChimpSubscribe = ({
	baseUrl,
	userId,
	listId,
	formId,
	tagId,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const formUrl = `${baseUrl}u=${userId}&id=${listId}&f_id=${formId}`;
	const tagOverride = { ...override("input4"),
		value: tagId // Use dynamic tag value

	};
	return <QuarklycommunityKitMailChimp url={formUrl} {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text")} />
					<Input {...override("input")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Text {...override("text1")} />
					<Input {...override("input1")} />
				</Box>
			</Box>
			<Box {...override("box5")}>
				<Box {...override("box6")}>
					<Text {...override("text2")} />
					<Input {...override("input2")} />
				</Box>
			</Box>
			<Box {...override("box7")}>
				<Input {...override("input3")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box8")}>
				<Input {...tagOverride} />
			</Box>
			<Box {...override("box9")}>
				<Box {...override("box10")}>
					<Button {...override("button")}>
						Feliratkozom
					</Button>
				</Box>
			</Box>
		</Box>
		{children}
	</QuarklycommunityKitMailChimp>;
};

Object.assign(MailChimpSubscribe, { ...QuarklycommunityKitMailChimp,
	defaultProps,
	overrides,
	propInfo
});
export default MailChimpSubscribe;