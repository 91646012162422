import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text } from "@quarkly/widgets";
const defaultProps = {
	"title": "Kérdezz és Felelek",
	"display": "inline",
	"children": "K&F"
};
const overrides = {};

const ToolTip = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Text {...rest}>
		{children}
	</Text>;
};

Object.assign(ToolTip, { ...Text,
	defaultProps,
	overrides
});
export default ToolTip;