import React, { useState, useEffect, useRef } from "react";
import { useOverrides } from "@quarkly/components";
import { Image, Box, Text, Icon } from "@quarkly/widgets";
import { motion, AnimatePresence } from "framer-motion";
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"justify-self": "center",
	"sm-display": "flex",
	"sm-flex-direction": "column",
	"sm-align-items": "center",
	"flex-direction": "column",
	"justify-items": "center",
	"max-width": "400px",
	"display": "grid",
	textContent: "Default text",
	showExtra: "Show"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "125px",
			"height": "125px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://images.unsplash.com/photo-1615485737651-580c9159c89a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000",
			"display": "block",
			"width": "120px",
			"border-radius": "50%",
			"lg-max-width": "100%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "25px 0px 0px 0px",
			"color": "--dark",
			"font": "--lead",
			"text-align": "center",
			"children": "Example Name"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "8px 0px 0px 0px",
			"color": "--greyD2",
			"font": "--base",
			"text-align": "center",
			"children": "Position"
		}
	}
};
const propInfo = {
	textContent: {
		title: "Text content",
		description: "Extended text for modal. Use <newline> for new line.",
		control: "input",
		category: "Main",
		weight: 1
	},
	showExtra: {
		title: "Enable Extra Text",
		description: "Toggle the feature on or off",
		control: "radio-group",
		variants: ["Show", "Hide"],
		category: "Main",
		weight: 1
	}
};
const MotionBox = motion(Box);
const variants = {
	default: {
		scale: 1,
		opacity: 1,
		position: 'static',
		border: '2px solid rgba(72, 124, 207, 0.3)',
		borderRadius: '15px',
		transition: {
			duration: 1
		}
	},
	expanded: {
		scale: 1,
		opacity: 0.03,
		position: 'static',
		border: '2px dashed rgba(72, 124, 207, 0.3)',
		borderRadius: '5px',
		transition: {
			duration: 1
		}
	}
};

const formatText = inputText => {
	// Split the input string by "\n"
	const parts = inputText.split('<newline>'); // Map over the parts and wrap each in a <Text> component

	return parts.map((part, index) => <Text key={index}>
		{part}
	</Text>);
};

const TeamMember = ({
	isSelected = false,
	index,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [zIndex, setZIndex] = useState(0);
	const parentRef = useRef();

	const updateZIndex = () => {
		const now = new Date();
		const startOfYear = new Date(now.getFullYear(), 0, 1);
		const millisecondsSinceStartOfYear = now - startOfYear;
		const newZIndex = Math.floor(millisecondsSinceStartOfYear / 1000);
		setZIndex(newZIndex); //console.log("zIndex =", newZIndex);
	};

	useEffect(() => {
		updateZIndex();
	}, [isSelected]);
	return <Box
		position="relative"
		cursor={props.showExtra === "Show" ? "pointer" : "auto"}
		ref={parentRef}
		key={`main${index}`}
		{...rest}
	>
		<MotionBox
			padding="10px 0"
			layout
			box-shadow="0 20px 25px -5px rgba(0, 0, 0, 0.1)"
			variants={variants}
			initial='default'
			animate={isSelected && props.showExtra === "Show" ? 'expanded' : "default"}
			{...override('MainMotionBox')}
		>
			{props.showExtra === "Show" && <Icon {...override("More icon")} />}
			<Box {...override("box")}>
				<Image {...override("image")} />
			</Box>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			{children}
		</MotionBox>
		<AnimatePresence>
			{isSelected && props.showExtra === "Show" && <MotionBox
				drag
				dragConstraints={{
					top: -50,
					left: -10,
					right: 10,
					bottom: 50
				}}
				layout
				width="80%"
				max-width="450px"
				z-index={`${zIndex}`}
				key={`sub${index}`}
				initial={{
					opacity: 0,
					scale: 0,
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					position: "fixed",
					justifySelf: "center",
					alignSelf: "center"
				}}
				animate={{
					opacity: 0.95,
					scale: 1,
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					transition: {
						duration: 0.5
					}
				}}
				exit={{
					opacity: 0,
					scale: 0,
					left: 0,
					top: 0,
					transition: {
						duration: 0.5
					}
				}}
				{...override('SubMotionBox')}
			>
				<Box {...override('ClonedChildren')}>
					<Box {...override('CloseIconWrapper')}>
						<Icon {...override('CloseIcon')} />
					</Box>
					<Box {...override("box")}>
						<Image {...override("image")} />
					</Box>
					<Text {...override("text")} />
					<Text {...override("text1")} />
					{React.Children.map(children, (child, idx) => React.cloneElement(child, {
						key: `cloned-${idx}`
					}))}
				</Box>
				<Text {...override('PropText')}>
					{formatText(props.textContent)}
				</Text>
			</MotionBox>}
		</AnimatePresence>
	</Box>;
};

Object.assign(TeamMember, { ...Box,
	defaultProps,
	overrides,
	propInfo
});
export default TeamMember;